<template>
  <v-content justify="center">
    <v-row justify="center">
      <v-col class="text-center" cols="12" md="6">
        <div class="headline">Necesitas validar tu correo electrónico</div>
        <br />
        <p>Por favor revisa el correo electónico que acabamos de enviar para que puedas verificar que tenemos un medio válido para comunicarnos contigo.</p>
        <div class="ma-4 text-center" justify="center">
          <v-divider></v-divider>
        </div>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" md="6" class="pb-3 font-weight-light" align="center">
        Sí no has recibido el correo de confimación y ya revisaste en tu sección de SPAM puedes solicitar un nuevo correo de confirmación siguiendo este link:
        <br />>
        <a @click="resendVerification()" href="#">
          <strong>Re enviar mail de verificación</strong>
        </a>

        <p>Si ya realizaste la verificación puedes continuar al LogIn</p>
        <div>
          <v-btn to="/login">Inicia Sesión</v-btn>
        </div>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar">
      {{ textSnack }}
      <v-btn color="pink" text @click="snackbar = false">Cerrar</v-btn>
    </v-snackbar>
  </v-content>
</template>

<script>
import firebase from "firebase";
export default {
  methods: {
    resendVerification() {
      console.log("here 2");
      var self2 = this;
      firebase.auth().languageCode = "es";
      console.log(firebase.auth());
      firebase
        .auth()
        .currentUser.sendEmailVerification()
        .then(function() {
          // alert("El email de verificación ha sido re enviado");
          self2.textSnack =
            "El email de verificación se ha enviado correctamente";
          self2.snackbar = true;
        })
        .catch(function(error) {
          console.log("error en email validación");
          self2.textSnack =
            "Se ha producido un error al enaviar el mail de verificación." +
            error.message;
          self2.snackbar = true;
          console.log(error);
        });
    }
  },
  data() {
    return {
      snackbar: false,
      textSnack: ""
    };
  }
};
</script>